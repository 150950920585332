import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { API_URL } from 'assets';
import './About.scss'

import Header from './../../Components/Header/Header'
import Footer from './../../Components/Footer/Footer'

import Component from './../Component';

export default function About() {
  const [pageContent, setPageContent] = useState({});

  useEffect(() => {
    // Find the section with the class "round-corner"
    const roundCornerSection = document.querySelector('.round-corner-display');

    // If the round-corner section exists, find its next sibling section
    if (roundCornerSection) {
      const siblingSection = roundCornerSection.nextElementSibling;

      // If the sibling section exists and is a <section> element, add the class "under-round-corner"
      if (siblingSection && siblingSection.tagName.toLowerCase() === 'section' || siblingSection && siblingSection.tagName.toLowerCase() === 'footer') {
        siblingSection.classList.add('under-round-corner-display');
      }
    }
  }, [pageContent]);

  useEffect(() => {
    const getPageContent = async () => {
      const pageContentResponse = await fetch(`${API_URL}/pages/14`)
      setPageContent(await pageContentResponse.json())
    }
    getPageContent();
  }, []);

  if (!pageContent || !pageContent.acf || !pageContent.acf.components) return null;

  return (
    <>
      <Helmet bodyAttributes={{ class: 'page about' }}>
        <title>II Technology | About II Technology</title>
        <meta name="description" content="Founded in 2016, II Technology specializes in innovative investment management solutions for advisors and their clients." />
      </Helmet>

      <Header />

      {pageContent.acf.components.map((component, index) => <Component component={component} key={index} />)}

      <Footer />

    </>
  )
}
