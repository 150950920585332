import { useState, useEffect } from 'react'
import './UserType.scss'

import Dots from './assets/dots-graphic-bg.svg';

export default function UserType(component) {

  const data = component.data ;

  if (!data) return null;

  return (
    <section className="user-type">
      <div className="container">
        <div className="content-container">
          {data.user_type.map(type => (
            <div className="card-info">
              <div className='user-description' key={type.label}>
                <h2 dangerouslySetInnerHTML={{ __html: type.label }}></h2>
                <p dangerouslySetInnerHTML={{ __html: type.body_copy }}></p>
              </div>
              <div className="cta">
                <a href={type.cta.url}>{type.cta.title}</a>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="graphic">
        <img src={Dots} alt="II Technology background graphic" loading="lazy"/>
      </div>
    </section>
  )
}
