import { useState, useEffect } from 'react'
// import { GOOGLE_MAPS_API } from 'assets';
// import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import './ContactAndLocation.scss'

export default function ContactAndLocation(component) {

  const data = component.data;

  if (!data) return null;

  return (
    <section className="contact-and-location">
      <div className="team">
        <div className="container">
          <div className="content-container">
                {data.employee_contacts.map((employee) => (
                  <div className="employee" key={employee.name}>
                    <img src={employee.headshot.url} alt={employee.name} className="headshot" loading="lazy"/>
                    <h4 dangerouslySetInnerHTML={{ __html: employee.name }}></h4>
                    <p dangerouslySetInnerHTML={{ __html: employee.title }} className="title"></p>
                    <p className="email">
                      <a href={`mailto:${employee.email}`}>{employee.email}</a>
                    </p>
                  </div>
                ))}
          </div>
        </div>
      </div>
      <div className="company">
        <div className="container">
          <div className="content-container">
            <h2 dangerouslySetInnerHTML={{ __html: data.company_name }}></h2>
              <p className="phone">
                <a href={`tel:${data.company_phone}`}>{data.company_phone}</a>
              </p>
              <p dangerouslySetInnerHTML={{ __html: data.company_address }} className="address"></p>
          </div>
        </div>
      </div>
    </section>
  )
}
