import { useState, useEffect } from 'react'
import './Hero.scss'

import Dots from './assets/dots-graphic-bg.svg';

export default function Hero(component) {

  const data = component.data;


  useEffect(() => {
    const preloadImage = document.createElement('link');
    preloadImage.rel = 'preload';
    preloadImage.href = data.background_image.url;
    preloadImage.as = 'image';
    document.head.appendChild(preloadImage);
  }, []);

  useEffect(() => {
    const preloadImage = document.createElement('link');
    preloadImage.rel = 'preload';
    preloadImage.href = data.mobile_background_image.url;
    preloadImage.as = 'image';
    document.head.appendChild(preloadImage);
  }, []);


  if (!data) return null;

  return (
    <section className={`hero`}>
      <div className="round-corner">
        {data.background_image.url ?
          <>
            {data.mobile_background_image.url ?
              <>
              <img src={data.background_image.url} alt="II Technology" className="custom-image desktop" loading="lazy"/>
              <img src={data.mobile_background_image.url} alt="II Technology" className="custom-image mobile" loading="lazy"/>
            </>
              :
            <img src={data.background_image.url} alt="II Technology" className="custom-image" loading="lazy"/>
            }
          </>
          :
          <img src={Dots} alt="II Technology background graphic" className="dots" loading="lazy"/>
          }
        </div>
        < div className="page-title">
            <div className="container">
          <h1 dangerouslySetInnerHTML={{ __html: data.page_header }} className={`${data.header_color} ${!data.mobile_background_image.url ? "default" : ""}`}></h1>
            </div>
        </div>
      </section>
  )
}
