import { useState, useEffect } from 'react'
import './TeamMember.scss'

export default function TeamMember({ member }) {

  const [personalDisplay, setPersonalDisplay] = useState(false);

  return (
    <div className={`member ${personalDisplay ? "personal-active" : "professional-active"}`} key={member.name}>
      <div className="headshot">
        <img src={member.headshot_professional.url} alt={member.name} className="professional" loading="lazy"/>
      </div>
      <div className="about">
        <h3 dangerouslySetInnerHTML={{__html:member.name}}></h3>
        <p dangerouslySetInnerHTML={{ __html: member.title }} className="title"></p>
        <div className="description-container">
          <p dangerouslySetInnerHTML={{ __html: member.about_professional }} className="professional"></p>
        </div>
      </div>
    </div>
  )
}
