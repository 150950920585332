import { useState, useEffect } from 'react'
import './AdvisorServicesCallout.scss'

import NavArrow from './../assets/nav-arrow-children.svg';

export default function AdvisorServicesCallout(component) {

  const data = component.data;

  if (!data) return null;

  return (
    <section className={`advisor-services-callout ${data.round_corner_display ? "round-corner-display" : ""}`}>
      <div className="container">
        <div className="content-container">
          <div className="message">
            <h2 dangerouslySetInnerHTML={{ __html: data.headline }}></h2>
            <p dangerouslySetInnerHTML={{ __html: data.body_copy }}></p>
          </div>
          <div className="featured-advisors">
            {data.featured_advisors.map(advisor => (
              <div className="advisor" key={advisor.advisor_name}>
                <a href={advisor.advisor_website} target="_blank">
                  <img src={advisor.advisor_logo.url} alt={advisor.advisor_name} className="logo" loading="lazy" />
                </a>
              </div>
            ))}
          </div>
          <div className='disclosure'>
            <p>{data.disclosure}</p>
          </div>
        </div>
      </div>
    </section>
  )
}
