import { React } from 'react'
import { useState, useEffect } from 'react';
import { Navigate, Routes, Route, useLocation } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { OptionsContextProvider } from './OptionsContext';
import { AnimatePresence, motion } from 'framer-motion';

// Components
// import Header from './Components/Header/Header'
// import Footer from './Components/Footer/Footer'

// Pages
import Home from './pages/Home/Home'
import About from './pages/About/About'
import BenefitsForInvestors from './pages/BenefitsForInvestors/BenefitsForInvestors'
import BenefitsForAdvisors from './pages/BenefitsForAdvisors/BenefitsForAdvisors'
import Contact from './pages/Contact/Contact'

function App() {
  const location = useLocation();

  return (
    <>
      <HelmetProvider>

        <Helmet bodyAttributes={{ class: 'page home' }}>
          <title>II Technology | About ARMOR</title>
          <meta name="description" content="Manage risk and maximize opportunity with the next generation of risk management - II Technology's Active Risk Management Overlay Regimen® (ARMOR™)." />
        </Helmet>

        <OptionsContextProvider>

          {/* <Header /> */}

          <AnimatePresence mode='wait'>
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/armor-for-investors" element={<BenefitsForInvestors />} />
            <Route path="/armor-for-advisors" element={<BenefitsForAdvisors />} />
            <Route path="/contact" element={<Contact />} />

            <Route path="*" element={<Navigate to='/'/>} />
            </Routes>
          </AnimatePresence>

          {/* <Footer /> */}

        </OptionsContextProvider>
      </HelmetProvider>
    </>
  );
}

export default App;
